.App {
	text-align: center;
	font-family: Arial,Helvetica,sans-serifbody;
}

.header-logo {
	text-align: left;
}

.header-company-name {
	text-align: left;
	display: table-cell !important;
	vertical-align: center;
	margin: auto;
	padding-left: 0px !important;
	line-height: 1.0;
}

.header-company-name-span {
	font-size: 1.375rem;
	font-weight: 700;
}

.header-company-tagline-span {
	font-size: 1.0rem;
}

@media (max-width: 1000px) {
	.header-company-tagline-span {
		font-size: 0.9rem;
	}
}

.app-body {
	padding-top: 0px;
}

.app-footer {
	background: #282828;
	color: #FFFFFF;
	padding-top: 50px;
	padding-bottom: 50px;
}

.href-no-hover A:link {text-decoration: none; color: #FFFFFF;}
.href-no-hover A:visited {text-decoration: none; color: #FFFFFF;}
.href-no-hover A:hover {text-decoration: none; color: #FFFFFF;}
.href-no-hover A:active {text-decoration: none; color: #FFFFFF;}

.app-footer-company {
	font-size: 1.375rem;
	font-weight: 700;
}
.app-footer-mailto {
	padding-top: 10px;
	font-weight: 600;
}
.app-footer-phone {
	padding-top: 10px;
}
.app-footer-social {
	font-size: 1.375rem;
	padding-top: 10px;
}
.app-footer-copyright {
	padding-top: 10px;
}

.contentarea-single-center {
	padding: 12%;
	background-size: cover;
}

.contentarea-single-center.overview-rationale {
	padding: 150px;
	background-size: cover;
}

.contentarea-single-center-surround {
	padding: 220px 15%;
	background: rgb(228, 228, 231);
}

.contentarea-single-center-surround.overview-rationale {
	background: rgb(255, 255, 255);
	padding: 150px 10%;
	text-align: left;
}

@media (max-width: 1000px) {

	.contentarea-single-center-surround.overview-rationale {
		padding: 75px 10%;
	}

	.contentarea-single-center.overview-rationale {
		padding: 0px;
	}

	.contentarea-single-center-surround {
		padding: 110px 15%;
	}
	
	.contentarea-single-center {
		padding: 0px;
	}
}

.contentarea-single-center-title {
	color: rgb(23, 28, 180);
	font-size: 4.0rem;
	font-weight: 900;
	line-height: 1.0;
}

@media (max-width: 1000px) {
	.contentarea-single-center-title {
		font-size: 2.5rem;
	}
}

.contentarea-single-center-title.overview-rationale {
	color: rgb(23, 28, 180);
	font-size: 2.5rem;
	font-weight: 900;
	line-height: 1.0;
}

.contentarea-single-center-tagline {
	padding-top: 25px;
	color: rgb(24, 24, 24);
	font-size: 1.25rem;
	font-weight: 600;
}

.contentarea-single-center-content {
	padding-top: 25px;
	color: rgb(24, 24, 24);
	font-size: 1.0rem;
	font-weight: 450;
	line-height: 2.0;
}

.contentarea-single-center-content.overview-rationale {
	padding-top: 25px;
	color: rgb(24, 24, 24);
	font-size: 1.125rem;
	font-weight: 450;
	line-height: 2.0;
}

.contentarea-single-center-button {
	padding-top: 20px;
}

.sidebyside {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	text-align: left;
}

@media (min-width: 1000px) {

	.sidebyside-empty-img {
		background-size: cover;
		height: 100%;
	}

	.sidebyside-empty-img img {
		display: none;
	}

	.sidebyside-col1 {
		flex: 1;
	}
	
	.sidebyside-col2 {
		flex: 1;
	}
}

@media (max-width: 1000px) {
	
	.sidebyside-empty-img img {
		width: 100%;
		height: 100%
	}

	.sidebyside-empty-img span {
		display: none;
	}

	.sidebyside-empty-img {
		background-image: "";
	}
}

.sidebyside-col1-surround {
	background: rgb(23,28,180);
	padding-left: 18%;
	padding-right: 18%;
	padding-top: 80px;
	padding-bottom: 80px;
	height: 100%;
}

.sidebyside-col1-surround.page-about {
	padding-top: 120px;
	padding-bottom: 120px;
}

.sidebyside-col1-title {
	color: white;
	font-size: 2.5rem;
	font-weight: 600;
	line-height: 1.0;
}

.sidebyside-col1-tagline {
	padding-top: 30px;
	color: white;
	font-size: 1.125rem;
	font-weight: 550;
	line-height: 1.3;
	
}
.sidebyside-col1-content {
	padding-top: 20px;
	color: white;
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.3;
	
}
.sidebyside-col1-button {
	padding-top: 30px;
	color: white;
}

.sidebyside-col3 {
	flex: 1;
	padding: 0;
	margin: 0;
}

.sidebyside-col3-surround {
	background: rgb(255, 255, 255);
	padding-left: 8%;
	padding-right: 8%;
	padding-top: 50px;
	padding-bottom: 40px;
}

.sidebyside-col3-title {
	color: rgb(23,28,180);
	font-size: 2.5rem;
	font-weight: 600;
	line-height: 1.0;
}
.sidebyside-col3-tagline {
	padding-top: 30px;
	color: white;
	font-size: 1.125rem;
	font-weight: 550;
	line-height: 1.3;
	
}
.sidebyside-col3-content {
	padding-top: 20px;
	color: white;
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.3;
	
}
.sidebyside-col3-button {
	padding-top: 30px;
	color: white;
}

.sidebyside-col3-form {
	padding-top: 20px;
	padding-bottom: 0px;
}

.sidebyside-col3-spacer {
	height: 10px;
}

.ourfounder {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	text-align: left;
	background: rgb(228, 228, 231);
	padding-top: 80px;
	padding-bottom: 80px;
}

.ourfounder-col1 {
	text-align: right;
	display: table-cell !important;
	vertical-align: center;
	margin: auto;
	padding: 50px;
}

.ourfounder-col2 {
	padding: 50px;
}

@media (max-width: 1000px) {
	.ourfounder-col1 {
		padding: 8%;
	}
	.ourfounder-col2 {
		padding: 8%;
	}
}

@media (min-width: 1000px) {
	.ourfounder-col1 {
		flex: 1;
	}
	.ourfounder-col2 {
		flex: 1;
	}
}

.ourfounder-col2-title {
	color: rgb(23,28,180);
	font-size: 2.5rem;
	font-weight: 600;
	line-height: 1.0;	
}
.ourfounder-col2-tagline {
	padding-top: 40px;
	font-size: 1.375rem;
	font-weight: 600;
	line-height: 1.3;
}

.ourfounder-col2-content {
	padding-top: 40px;
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.3;	
}

.overview-wesupport {
	padding-top: 60px;
	padding-bottom: 40px;
	padding-left: 5%;
	padding-right: 5%;
}

.overview-wesupport div:nth-child(1) {
	color: rgb(23,28,180);
	font-size: 2.5rem;
	font-weight: 600;
	line-height: 1.0;	
}

.overview-wesupport div:nth-child(2) {
	padding-top: 20px;
	font-size: 1.125rem;
	font-weight: 500;
	line-height: 1.0;	
}

.featurebenefit {
	display: flex;
	flex-wrap: wrap;
	color: white;
	text-align: left;
	padding-top: 40px;
	padding-bottom: 60px;
}

.featurebenefit-wrapper1 {
	margin-left: 5%;
	margin-right: 1%;
}         

.featurebenefit-col1 {
	background: rgb(23,28,180);
	padding: 40px;
	height: 100%;
}

.featurebenefit-wrapper2 {
	margin-right: 5%;
	margin-left: 1%;
}         

.featurebenefit-col2 {
	background: rgb(0,153,255);
	padding: 40px;
	height: 100%;
}

@media (max-width: 1000px) {
	.featurebenefit-wrapper1 {
		margin: 3% 20px;
		width: 100%;
	}
	.featurebenefit-wrapper2 {
		margin: 3% 20px;
		width: 100%;
	}
}

@media (min-width: 1000px) {
	.featurebenefit-wrapper1 {
		flex: 1;
	}
	.featurebenefit-wrapper2 {
		flex: 1;
	}
}

.featurebenefit-title {
	font-size: 2.5rem;
	font-weight: 600;
	line-height: 1.0;	
}
.featurebenefit-wrapper {
	font-size: 1.625rem;
	font-weight: 500;
	line-height: 1.0;
	padding-top: 30px;
}

.featurebenefit-wrapper div:nth-child(2) {
	padding-top: 8px;
	font-style: italic;
}

.flowchart {
	display: flex;
	flex-wrap: wrap;
	padding-top: 60px;
	padding-bottom: 60px;
}
.flowchart-col1 {
	text-align: left;
	padding-left: 5%;
	padding-right: 5%;
	padding-top: 60px;
}
.flowchart-col2 {
}
.flowchart-title {
	color: rgb(23,28,180);
	font-size: 2.5rem;
	font-weight: 600;
	line-height: 1.0;	
	
}
.flowchart-content {
	padding-top: 10px;
	font-size: 1.375rem;
	font-weight: 500;
	line-height: 1.2;	

}

@media (min-width: 1000px) {
	.flowchart-col1 {
		flex: 1
	}
	.flowchart-col2 {
		flex: 1
	}
}

.technology {
	background: rgb(23,28,180);
	color: white;
	text-align: left;
	padding: 60px 8%;
}

.technology-title {
	text-align: center;
	font-size: 2.5rem;
	font-weight: 600;
	padding-bottom: 40px;
}

.technology-grid {
	display: flex;
	flex-wrap: wrap;
}

.technology-grid-col-text {
	display: flex;
	justify-content: center;
	align-items: center; 
}

.technology-grid-col-title {
	font-size: 1.5rem;
	font-weight: 500;
}

.technology-grid-col-content {
	font-size: 1.0rem;
	font-weight: 400;
	padding-top: 10px;
}

.technology-grid-col-image {
	padding: 30px;
}

.technology-grid-text-wrapper {
	padding: 30px;
}

@media (min-width: 1000px) {
	.technology-grid-col-text {
		flex: 1
	}
	.technology-grid-col-image {
		flex: 1
	}
}

.direct-connect-header {
}
.direct-connect-header-surround {
	background: rgb(228, 228, 231);
	padding: 40px 12%;
}

.direct-connect-header-title {
	color: rgb(23, 28, 180);
	font-size: 2.5rem;
	font-weight: 900;
	line-height: 1.0;

}
.direct-connect-header-tagline {
	padding-top: 25px;
	color: rgb(24, 24, 24);
	font-size: 1.5rem;
	font-weight: 600;

}
.direct-connect-header-content {
	padding-top: 25px;
	color: rgb(24, 24, 24);
	font-size: 1.2rem;
	font-weight: 450;
	line-height: 1.5;
}

.directpromotion-graphic {
	padding: 60px 20%;
}

.directpromotion-graphic img {
	max-width: 600px;
}

.twopronged {
	display: flex;
	flex-wrap: wrap;
	padding-top: 40px;
	padding-bottom: 40px;
}   
.twopronged-wrapper1 {
	margin-left: 5%;
	margin-right: 1%;
}         
.twopronged-col1 {
	background: rgb(228, 228, 231);
	padding: 40px;
	height: 100%;
}            
.twopronged-wrapper2 {
	margin-right: 5%;
	margin-left: 1%;
	
}         
.twopronged-col2 {
	background: rgb(228, 228, 231);   
	padding: 40px;
	height: 100%;
}      

@media (max-width: 1000px) {
	.twopronged-wrapper1 {
		margin: 3% 20px;
	}
	.twopronged-wrapper2 {
		margin: 3% 20px;
	}
}

@media (min-width: 1000px) {
	.twopronged-wrapper1 {
		flex: 1;
	}
	.twopronged-wrapper2 {
		flex: 1;
	}
}

.twopronged-title {
	color: rgb(23,28,180);
	font-size: 3.0rem;
	font-weight: 600;
	line-height: 1.0;	    
}            

.twopronged-tagline {
	padding-top: 14px;
	color: rgb(24, 24, 24);
	font-size: 1.2rem;
	font-weight: 500;
	font-style: italic;
    
}            

.twopronged-content {
	padding-top: 16px;
	color: rgb(24, 24, 24);
	font-size: 1.0rem;
	font-weight: 400;
	line-height: 1.5;

}            

.faq-page-title {
	background: rgb(23,28,180);	
	color: white;
	padding-top: 60px;
	padding-bottom: 60px;
	font-size: 2.5rem;
	font-weight: 600;
	line-height: 1.0;	
}
.faq-section-title {
	background: rgb(0,153,255);
	color: white;
	padding-top: 60px;
	padding-bottom: 60px;
	font-size: 2.5rem;
	font-weight: 600;
	line-height: 1.0;	
}
.faq-section {
	text-align: left;
	padding-top: 50px;
	padding-bottom: 30px;
	padding-left: 12%;
	padding-right: 12%;
}
.faq-item {

}
.faq-title {
	color: rgb(23,28,180);	
	font-size: 1.5rem;
	font-weight: 600;
	line-height: 1.0;	
}
.faq-content {
	padding-top: 20px;
	padding-bottom: 30px;
	font-size: 1.2rem;
}

.faq-content-note {
	font-style: italic;
}